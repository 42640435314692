function openCloseNav() {
  if (document.getElementById("navigation-overlay").style.width === "100%") {
    document.getElementById("navigation-overlay").style.width = "0%";
  } else {
    document.getElementById("navigation-overlay").style.width = "100%";
  }
}

function closeNav() {
  document.getElementById("navigation-overlay").style.width = "0%";
}

document.addEventListener(
  "click",
  function openNavClicked(event) {
    if (
      event.target.matches(".navigation-overlay-open") ||
      event.target?.ownerSVGElement?.parentElement?.matches(
        ".navigation-overlay-open"
      )
    ) {
      event.preventDefault();
      openCloseNav();
    }

    if (event.target.matches(".navigation-overlay-close")) {
      event.preventDefault();
      closeNav();
    }
  },
  false
);
