window.dataLayer = window.dataLayer || [];
function gtag(...args) {
  window.dataLayer.push(args);
}

const activeEnvironment = "production";

function sendPageEvent(event) {
  const socialVariablesElement = document.querySelector(".social-variables");
  const googleAnalyticsAccount =
    socialVariablesElement.getAttribute("google_tag");

  gtag("config", googleAnalyticsAccount, {
    page_location: event.data.url,
  });
}

function sendGaEvent(category, action, label, value, destination) {
  const socialVariablesElement = document.querySelector(".social-variables");
  const googleAnalyticsAccount =
    socialVariablesElement.getAttribute("google_tag");
  let functionLabel = label;
  if (functionLabel == null) {
    functionLabel = null;
  }
  let functionValue = value;
  if (functionValue == null) {
    functionValue = null;
  }
  let functionDestination = destination;
  if (functionDestination == null) {
    functionDestination = null;
  }

  return gtag("event", action, {
    send_to: googleAnalyticsAccount,
    event_category: category,
    event_label: functionLabel,
    event_value: functionValue,
    page_path: functionDestination,
  });
}
function sendReEvent() {
  // const socialVariablesElement = document.querySelector(".social-variables");
  // const googleRemarketingAccount =
  //   socialVariablesElement.getAttribute("google_remarketing");

  // return gtag("event", event, {
  //   send_to: googleRemarketingAccount,
  //   user_id: user,
  // });

  return null;
}

document.addEventListener(
  "turbolinks:load",
  function googleAnalyticsDocumentReady(e) {
    const socialVariablesElement = document.querySelector(".social-variables");
    const environment = socialVariablesElement.getAttribute("environment");
    if (environment !== activeEnvironment) {
      return;
    }
    sendPageEvent(e);

    const analyticsNodes = document.querySelectorAll(".google-analytics");
    analyticsNodes.forEach((node) => {
      const category = node.getAttribute("category");
      const action = node.getAttribute("action");
      const label = node.getAttribute("label");
      const value = node.getAttribute("value");
      const destination = node.getAttribute("destination");
      return sendGaEvent(category, action, label, value, destination);
    });

    // const remarketingNodes = document.querySelectorAll(".google-remarketing");
    // return remarketingNodes.forEach((node) => {
    //   const event = node.getAttribute("event");
    //   const user = node.getAttribute("user");
    //   return sendReEvent(event, user);
    // });
  }
);

export { sendGaEvent, sendReEvent };
