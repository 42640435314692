$(document).on("turbolinks:load", function brochureLoad() {
  $(".recaptcha-form").recaptchaForm();
});

$.fn.recaptchaForm = function brochureOutOfSeasonRecaptcha() {
  $(this).submit(function brochureOutOfSeasonRecaptchaSubmit(event) {
    if (!grecaptcha.getResponse()) {
      event.preventDefault();
      $(this).find("input:submit").attr("disabled", "disabled");
      return grecaptcha.execute();
    }
  });
  return (window.recaptchaDone = function brochureOutOfSeasonRecaptchaDone() {
    const recaptchaDataRemoteForm = $(".recaptcha-data-remote-form")[0];
    if (recaptchaDataRemoteForm)
      recaptchaDataRemoteForm.setAttribute("data-remote", "true");
    Rails.fire($("form.recaptcha-form")[0], "submit");
      changeOutOfSeasonMessagingAfterSubmit()
    if (recaptchaDataRemoteForm)
      recaptchaDataRemoteForm.removeAttribute("data-remote", "true");
    return $(this).find("input:submit").removeAttr("disabled");
  });
};


function changeOutOfSeasonMessagingAfterSubmit(){
    if(document.getElementById('mailing_list_grad_year') != undefined){
      year_selected = parseInt(document.getElementById('mailing_list_grad_year').value)
      next_year = year_selected + 1
      text = String(year_selected) + "-" + String(next_year)
      display_text = "We will reach out when the application period for " + text + " school year opens.  Thank you!"
    }else{
      display_text = "We will reach out when the application period for Fall Semester opens.  Thank you!"
    }

    $("#v3-out-of-season-form").hide(500, function() {
        $("#v3-out-of-season-form").remove()
    });
    $("#v3-out-of-season-title-id").hide(500, function() {
        $(this).text("You are on the list.");
        $(this).show(500)
    })
    $("#v3-out-of-season-text-id").hide(500, function() {
      $(this).text(display_text);
      $(this).show(500)
    })
}
