(function (w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
  const f = d.getElementsByTagName(s)[0];
  const j = d.createElement(s);
  const dl = l != "dataLayer" ? `&l=${l}` : "";
  j.async = true;
  j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
  const n = d.querySelector("[nonce]");
  n && j.setAttribute("nonce", n.nonce || n.getAttribute("nonce"));
  f.parentNode.insertBefore(j, f);
})(window, document, "script", "dataLayer", "GTM-TN2D2RJ");
