import { sendGaEvent } from "./google_analytics_events";

function sendGoogleAnalyticsOnClick(title) {
  const analyticsDiv = document.getElementsByClassName("google-analytics")[0];
  const category = analyticsDiv.getAttribute("category");
  const action = title;
  sendGaEvent(category, action);
}

document.addEventListener(
  "click",
  function faqClicked(event) {
    const { target } = event;
    if (target.matches(".faq-collapsible")) {
      event.preventDefault();
      target.classList.toggle("active");
      const content = target.nextElementSibling;
      if (content.style.display === "block") {
        content.style.display = "none";
        target.textContent = "Expand";
      } else {
        content.style.display = "block";
        target.textContent = "Hide";
        const title = event.target.previousElementSibling.textContent;
        sendGoogleAnalyticsOnClick(title);
      }
    }
  },
  false
);
